import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

const ProductSearcherListMobile = (props) => {
  const { productsPage, onScrollNext, onClickAddItem } = props;

  return (
    <div
      id="product-searcher-mobile-container-div"
      className="product-searcher-mobile-container"
      onScroll={() => {
        document
          .getElementById('product-searcher-mobile-container-div')
          .focus();
      }}
    >
      <InfiniteScroll
        scrollableTarget="product-searcher-mobile-container-div"
        dataLength={productsPage.data.content.length}
        next={onScrollNext}
        hasMore={!productsPage.data.last}
        loader={
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        }
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>No more products found.</b>
          </p>
        }
      >
        {/* Product card */}
        <Grid container alignItems="center" flexDirection="column" gap="1em">
          {productsPage.data.content.map((product, index) => {
            return (
              <Card
                key={index}
                sx={{
                  flexDirection: 'row',
                  display: 'flex',
                  backgroundColor: 'white',
                  minWidth: '185px',
                  width: '90%',
                }}
              >
                <CardMedia
                  sx={{ padding: '10px', width: '134px' }}
                  width="100px"
                  component="img"
                  alt={`${product?.label ?? ''} image`}
                  image={
                    product.attributes?.image?.thumbnail ??
                    'https://cash4toners.com/wp-content/themes/cash4toners/img/no-img.jpg'
                  }
                />
                <Box alignContent="center" sx={{ width: '100%' }}>
                  <CardContent sx={{}}>
                    <Typography
                      color="black !important"
                      sx={{
                        font: "500 16px 'Lexen', sans-serif",
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>{product.sku}</span>{' '}
                      {product?.label ? product.label.replace(product.sku, '') : ""}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <Button
                      className="c4t-add-item-to-list"
                      sx={{
                        color: '#0245B6',
                        borderColor: '#0245B6',
                        font: "14px 'Lexend', sans-serif",
                        minWidth: '100px',
                        minHeight: '29px',
                        padding: '7px !important',
                        marginBottom: '15px !important',
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => onClickAddItem(product)}
                    >
                      + Add
                    </Button>
                  </CardActions>
                </Box>
              </Card>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default ProductSearcherListMobile;
