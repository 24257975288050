import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import ProductSearcher from './ProductSearcher';
import {
  Button,
  Typography,
  Grid,
  Card,
  Box,
  CardContent,
} from '@mui/material';
import PropTypes from 'prop-types';
import classnames from '../../../../constants/classNames';
import AddedItem from './AddedItem';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import AddItemsHeader from './AddItemsHeader';
import toast from 'react-hot-toast';
import useAuth from '../../../../hooks/useAuth';
import { getToken } from '../../../../utils/httpUtils';
import { useGetBrandsQuery } from '../../../../store/apiSlice/wpSlice';
import '../../../../css/vendor-form.css';

const AddItems = ({ onCreate }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const [lineItems, setLineItems] = useState([]);
  const [addItemKey, setAddItemKey] = useState(Date.now());
  const [draftLoaded, setDraftLoaded] = useState(false);
  const [ignoreInitialization, setIgnoreInitialization] = useState(false);
  const isMobile = useIsMobileView();

  const formattedItems = useMemo(
    () =>
      lineItems.map((lineItem) => ({
        ...lineItem,
        product_id: lineItem?.product?.id?.replace?.('_compatible', '') ?? null,
        original: !lineItem?.brand?.includes('Compatible'),
      })),
    [lineItems]
  );

  const isFormattedItemsEmpty = formattedItems.length === 0;

  /**  Draft and quote initilization */
  const initializeLineItems = async () => {
    if (isAuthenticated) {
      const draftItems = await getDraft();
      setLineItems(draftItems);
    } else {
      const storedData = localStorage.getItem('localStorageLineItems');
      try {
        const parsedData = storedData
          ? JSON.parse(storedData, (key, value) => {
              return typeof value === 'string' &&
                value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/)
                ? new Date(value)
                : value;
            })
          : null;
        if (parsedData && parsedData.items) {
          setLineItems(parsedData.items);
        } else {
          setLineItems([]);
        }
      } catch (error) {
        console.error('Error parsing localStorage data', error);
        setLineItems([]);
      }
    }
  };

  const getDraft = async () => {
    try {
      const response = await fetch(`/api/vendor/quotes/draft`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching draft');
      }
      if (response === null || response === undefined) {
        return [];
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching draft:', error);
      return [];
    }
  };

  function handleDraft(newLineItems) {
    let lineItemsList;
    if (newLineItems !== undefined) {
      lineItemsList = newLineItems;
    } else {
      lineItemsList = lineItems;
    }
    if (draftLoaded) {
      if (!ignoreInitialization) {
        if (isAuthenticated) {
          const handleDraft = async () => {
            if (lineItemsList.length === 0) {
              await deleteDraft();
            } else {
              await updateDraft(lineItemsList);
            }
          };
          handleDraft();
        } else {
          if (lineItemsList.length === 0) {
            localStorage.removeItem('localStorageLineItems');
          } else {
            const currentDate = new Date().toISOString();
            const dataToStore = {
              lastUpdated: currentDate,
              items: lineItemsList,
            };
            localStorage.setItem(
              'localStorageLineItems',
              JSON.stringify(dataToStore)
            );
          }
        }
      } else {
        setIgnoreInitialization(false);
      }
    }
  }

  const updateDraft = async (lineItems) => {
    try {
      const response = await fetch(`/api/vendor/quotes/draft/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(lineItems),
      });

      if (!response.ok) {
        throw new Error('Error updating draft');
      }
    } catch (error) {
      console.error('Error updating draft:', error);
    }
  };

  const deleteDraft = async () => {
    try {
      const response = await fetch(`/api/vendor/quotes/draft/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error deleting draft');
      }
    } catch (error) {
      console.error('Error deleting draft:', error);
    }
  };

  /**  Quote item handling: add, remove, submit, change */
  const addItemHandler = useCallback(
    (newItem) => {
      const newLineItems = [...lineItems, newItem];
      setLineItems(newLineItems);
      setAddItemKey(Date.now());
      return newLineItems;
    },
    [lineItems]
  );

  const removeItemHandler = (itemKey, index) => {
    setLineItems(lineItems.filter((lineItem) => lineItem?.key !== itemKey));
    const newErrors = [...errors];
    newErrors[index] = {};
    setErrors(newErrors);
  };

  const handleSubmit = (newLineItems) => {
    let currentLineItems;
    if (newLineItems === undefined) {
      currentLineItems = lineItems;
    } else {
      currentLineItems = newLineItems;
      handleDraft(currentLineItems);
    }
    if (currentLineItems.some((item) => !item?.quantity)) {
      toast.error('Quantity is required for all items.');
      return;
    }
    onCreate(currentLineItems);
  };

  const handleBrandChange = (newBrand, lineItem) => {
    const newLineItems = lineItems.map((item) => {
      return item?.key === lineItem?.key
        ? { ...item, brand: newBrand.value }
        : item;
    });
    setLineItems(newLineItems);
    return newLineItems;
  };

  const handleConditionChange = (newCondition, lineItem) => {
    const newLineItems = lineItems.map((item) => {
      return item?.key === lineItem?.key
        ? { ...item, condition: newCondition }
        : item;
    });
    setLineItems(newLineItems);
    return newLineItems;
  };

  const handleExpirationChange = (newExpirationDate, lineItem) => {
    const newLineItems = lineItems.map((item) => {
      const expDate = item.product?.requires_expiration_date
        ? newExpirationDate
        : null;
      const boxDate = item.product?.requires_expiration_date
        ? null
        : newExpirationDate;
      const updatedItem =
        item.key === lineItem?.key
          ? {
              ...item,
              expirationDate: expDate,
              boxDate: boxDate,
            }
          : item;
      return updatedItem;
    });
    setLineItems(newLineItems);
    return newLineItems;
  };

  /** Brand loading */
  const { data: brands, isLoading: loadingBrands } = useGetBrandsQuery();

  /** Get paid and validation */
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const onClickHandler = async () => {
    let newLineItems;
    setIsActionLoading(true);

    const hasErrors = validateItems(lineItems);

    setIsActionLoading(false);

    // Submit data
    if (mounted.current && !hasErrors) {
      await handleSubmit(newLineItems);
      await deleteDraft();
      localStorage.removeItem('localStorageLineItems');
      setIsActionLoading(false);
    }
  };

  const validateItems = (lineItems) => {
    // Check and set errors
    const newErrors = [];
    let hasErrors = false;
    let i = 0;
    for (const item of lineItems) {
      // Check errors for each item
      const errors = validateItem(item);
      if (Object.keys(errors).length > 0) {
        hasErrors = true;
        newErrors[i] = errors;
      }
      i++;
    }
    setErrors(newErrors);

    return hasErrors;
  };

  const validateItem = (selectedProduct) => {
    const validationErrors = validate(selectedProduct);
    return validationErrors;
  };

  const validate = (product) => {
    const errors = {};

    const expirationDate = product?.expirationDate ?? null;
    if (!product || product?.userInput === '') {
      errors.product = 'Product is required';
    } else if (
      product?.product?.requires_expiration_date &&
      expirationDate === null
    ) {
      errors.requires_expiration_date =
        'Expiration date is required for inkjet cartridges';
    }

    const brand = product?.brand;
    if (!brand || brand === '') {
      errors.brand = 'Brand is required';
    }

    const quantity = product?.quantity;
    if (!quantity || !quantity > 0) {
      errors.quantity = 'Item quantity is required';
    }

    const condition = product?.condition;
    if (!condition) {
      errors.condition = 'Item condition is required';
    }

    return errors;
  };

  /** useEffect functions */
  useEffect(() => {
    if (isInitialized || window.wpForm === true) {
      initializeLineItems();
      setDraftLoaded(true);
      setIgnoreInitialization(true);
    }
  }, [isInitialized]);

  useEffect(() => {
    handleDraft();
  }, [lineItems]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  if (loadingBrands) {
    return <></>;
  }

  return (
    <Grid
      id="selling-form"
      container
      direction="column"
      spacing={2}
      className={classnames.quote.create.steps.addItems.container}
    >
      <Grid
        item
        pb={6}
        className={classnames.quote.create.steps.addItems.itemContainer}
      >
        <Card
          sx={{ backgroundColor: 'background.default' }}
          className={classnames.quote.create.steps.addItems.card}
        >
          <AddItemsHeader />
          <CardContent
            className={classnames.quote.create.steps.addItems.content}
            sx={{
              '&:last-child': {
                pb: 0,
              },
              p: 0,
            }}
          >
            {/** Add items form */}
            <Box>
              <div id='product-searcher-scroller'></div>
              {/** Step 1: Add items */}
              <ProductSearcher
                key={addItemKey}
                onAddItem={addItemHandler}
                lineItems={lineItems}
                brands={brands}
              />

              {/** Step 2: Enter quantity & condition */}
              <Grid
                container
                direction="row"
                sx={{
                  backgroundColor: 'white',
                  padding: '2em 0',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {/** Search your quantity and condition text. */}
                <Box sx={{ textAlign: 'center', marginBottom: '2em' }}>
                  <img
                    className="product-searcher-icon-box"
                    src="https://cash4toners.com/wp-content/themes/cash4toners/svg/open-box.svg"
                    alt="Packaging box icon"
                    width="20"
                    height="21"
                  />
                  <Typography
                    sx={{
                      display: 'inline',
                      font: 'bold 22px "Lexend", sans-serif',
                      marginLeft: '0.6em',
                    }}
                  >
                    Step 2: Enter Quantity & Condition
                  </Typography>
                </Box>

                {/** Empty products display. */}
                {isFormattedItemsEmpty && (
                  <Box
                    sx={{
                      width: isMobile ? '90%' : '75%',
                      padding: '53px 0',
                      border: '0.1em solid #C9C9C9',
                      borderRadius: '20px',
                    }}
                  >
                    <Typography
                      fontSize="20px"
                      fontFamily="'Lexend', sans-serif"
                      color="#969696"
                      textAlign="center"
                    >
                      Your items will appear here
                    </Typography>
                  </Box>
                )}

                {/** At least 1 product displayed. */}
                {!isFormattedItemsEmpty &&
                  formattedItems.map((lineItem, index) => (
                    <Box
                      margin="0 20px"
                      sx={
                        isMobile
                          ? { width: '100%' }
                          : { alignSelf: 'stretch', width: '90%' }
                      }
                      key={index}
                    >
                      <AddedItem
                        brands={brands}
                        item={lineItem}
                        errors={errors?.[index] ?? {}}
                        onRemove={() => removeItemHandler(lineItem.key, index)}
                        styles={{ backgroundColor: 'background.default' }}
                        onQuantityChange={(newQuantity) =>
                          setLineItems(
                            lineItems.map((item) =>
                              item?.key === lineItem?.key
                                ? { ...item, quantity: newQuantity }
                                : item
                            )
                          )
                        }
                        onConditionChange={(newCondition) => {
                          return handleConditionChange(newCondition, lineItem);
                        }}
                        onBrandChange={(newBrand) => {
                          return handleBrandChange(newBrand, lineItem);
                        }}
                        onExpirationDateChange={(newExpirationDate) => {
                          return handleExpirationChange(
                            newExpirationDate,
                            lineItem
                          );
                        }}
                        onValidate={(lineItems) => {
                          validateItems(lineItems);
                        }}
                      />
                    </Box>
                  ))}

                {!isFormattedItemsEmpty && isMobile && (
                  <Box sx={{ width: '100%', textAlign: 'center' }}>
                    <Button
                      sx={{
                        color: '#0245B6',
                        borderColor: '#0245B6',
                        font: "14px 'Lexend', sans-serif",
                        minWidth: '163px !important',
                        minHeight: '29px',
                        padding: '7px !important',
                        marginTop: '15px !important',
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => {document.getElementById("product-searcher-scroller").scrollIntoView()}}
                    >
                    + Add More Items
                    </Button>
                  </Box>
                )}
              </Grid>

              {/** Step 3: submit your quote */}
              <Grid
                container
                direction="column"
                sx={{ backgroundColor: '#F0F0F0', padding: '2em 0' }}
              >
                {/** Submit your quote text. */}
                <Box sx={{ textAlign: 'center', marginBottom: '2em' }}>
                  <img
                    className="product-searcher-icon-document"
                    src="https://cash4toners.com/wp-content/themes/cash4toners/svg/document.svg"
                    alt="Document icon"
                    width="20"
                    height="21"
                  />
                  <Typography
                    sx={{
                      display: 'inline',
                      font: 'bold 22px "Lexend", sans-serif',
                      marginLeft: '0.6em',
                    }}
                  >
                    Step 3: Submit Your Quote
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    onClick={onClickHandler}
                    disabled={isActionLoading}
                    sx={{
                      ml: 1,
                      width: '260px',
                      height: '50px',
                      font: "bold 20px 'Lexend', sans-serif",
                      background:
                        'linear-gradient(to right bottom, #7ED250, #0BA9DC 59%, #7A79E2 94%)',
                    }}
                    variant="contained"
                    className={`${classnames.buttons.primary} c4t-get-paid-button`}
                  >
                    Get Paid
                  </Button>
                  {errors.length > 0 && (
                    <Typography
                      color="rgb(211, 47, 47) !important"
                      fontFamily="'Lexend', sans-serif"
                      fontSize="14px"
                      marginTop={2}
                    >
                      Some fields are required. Please, check your items.
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddItems;
AddItems.propTypes = {
  onCreate: PropTypes.func.isRequired,
};
