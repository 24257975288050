import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress } from '@mui/material';
import {
  composeProductLabel,
  formatSku,
} from '../../../utils/quoteUtils';
import useIsMobileView from '../../../hooks/useIsMobileView';

const ItemProductImageAndText = ({ itemProduct, isLoading, original }) => {
  const isMobile = useIsMobileView();

  if (!isLoading && itemProduct !== undefined) {
    return (
      <Box display="flex" gap={2.5}>
        <ProductImage plytixProduct={itemProduct} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap={0.5}
          flexGrow={1}
          width={isMobile ? '110px' : '220px'}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            rowGap="0"
          >
            <Typography
              fontSize="20px"
              fontWeight="bold"
              fontFamily="'Lexend', sans-serif"
            >
              {itemProduct.sku ? formatSku(itemProduct?.sku) : itemProduct?.label ?? ""}
            </Typography>
          </Box>
          <Typography fontSize="16px" fontFamily="'Lexend', sans-serif">
            {composeProductLabel(itemProduct.label, original)}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
};

const ProductImage = ({ plytixProduct }) => {
  const imageSrc = plytixProduct?.attributes?.image?.thumbnail ?? "https://cash4toners.com/wp-content/themes/cash4toners/img/no-img.jpg";
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <img
        style={{
          width: '134px',
          marginLeft: '14px',
        }}
        src={imageSrc}
        alt={`Product ${plytixProduct?.label}`}
      />
    </Box>
  );
};

export default ItemProductImageAndText;
ProductImage.propTypes = {
  plytixProduct: PropTypes.object,
};
ItemProductImageAndText.propTypes = {
  isLoading: PropTypes.bool,
  item: PropTypes.object,
  itemProduct: PropTypes.object,
  original: PropTypes.bool.isRequired,
};
